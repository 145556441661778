import DealerInformation from "@/models/DealerInformation"
import Request from "@/services/Request"

const state = {
	information: {} as DealerInformation
}

const getters = {}

const actions = {
	async getInformation({ commit }, dealerId: string): Promise<unknown> {
		const response = await Request.getInstance().get(`${process.env.VUE_APP_API_URL}dealer/${dealerId}`)
		commit("setInformation", response.data)
		return Promise.resolve()
	}
}

// mutations
const mutations = {
	setInformation(state, info: DealerInformation): void {
		state.information = info
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
