import Vue from "vue"
import VueRouter from "vue-router"
import Task from "@/components/tasks/Task.vue"
import TaskList from "@/components/tasks/TaskList.vue"
import Privacy from "@/components/site/Privacy.vue"
import Terms from "@/components/site/Terms.vue"
import NotFound from "@/components/site/404.vue"
import InteractiveResponse from "@/components/proposals/InteractiveResponse.vue"

Vue.use(VueRouter)

const routes = [
	{
		path: "/proposals/:dealerId/:proposalId/:interactiveId/:termId",
		props: true,
		component: InteractiveResponse,
		name: "interactive-response"
	},
	{
		path: "/:dealerId/task/:taskId",
		props: true,
		component: Task,
		name: "task"
	},
	{
		path: "/:dealerId/task/:taskId/related",
		props: true,
		component: TaskList,
		name: "related-tasks"
	},
	{
		path: "/privacy",
		name: "privacy",
		component: Privacy
	},
	{
		path: "/terms",
		name: "terms",
		component: Terms
	},
	{
		path: "*",
		component: NotFound
	}
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
})

export default router
