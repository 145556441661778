import Vue from "vue"
import Vuetify from "vuetify"

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#0478D9",
				secondary: "#0464B3",
				accent: "#6ec6ff",
				warning: "#fb8c00",
				drawer: "#2f4050",
				background: "#FAFAFA"
			},
			dark: {
				primary: "#0478D9",
				secondary: "#0464B3",
				accent: "#6ec6ff",
				warning: "#fb8c00",
				drawer: "#2f4050"
			}
		}
	},
	icons: {
		iconfont: "md"
	}
})
