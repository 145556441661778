






















import Vue from "vue"
export default Vue.extend({
	created() {
		const dealerId = this.$route.params.dealerId
		if (dealerId) this.$store.dispatch("dealership/getInformation", dealerId)
		this.$vuetify.theme.dark = true
	}
})
