












import Request from "@/services/Request"
import Vue from "vue"
export default Vue.extend({
	data() {
		return {
			complete: false
		}
	},
	props: {
		dealerId: String,
		proposalId: String,
		interactiveId: String,
		termId: String
	},
	methods: {
		async completeResponse() {
			await Request.getInstance().post(
				`${process.env.VUE_APP_API_URL}proposals/${this.dealerId}/${this.proposalId}/${this.interactiveId}/${this.termId}`,
				{}
			)
			this.complete = true
		}
	},
	created() {
		this.completeResponse()
	}
})
