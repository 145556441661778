


























































































import Vue from "vue"
import Request from "@/services/Request"
import Task from "@/models/Task"
import TaskComment from "@/models/TaskComment"
import { DateTime } from "luxon"
export default Vue.extend({
	data() {
		return {
			DateTime,
			loading: true as boolean,
			notFound: false as boolean,
			task: null as unknown as Task,
			completeDialog: false as boolean,
			saving: false as boolean,
			addingComment: false as boolean,
			comment: null as unknown as string
		}
	},
	props: {
		dealerId: String,
		taskId: String
	},
	methods: {
		async completeTask() {
			this.saving = true
			await Request.getInstance().post(`${process.env.VUE_APP_API_URL}tasks/${this.dealerId}/${this.task.id}/complete`, {
				completedBy: this.task.name
			})
			this.task.completedAt = new Date()
			this.saving = false
			this.completeDialog = false
		},
		formatDate(date: Date): string {
			return DateTime.fromJSDate(date).toRelative()
		},
		async addComment() {
			if (!this.comment) {
				return
			}
			this.addingComment = true
			this.task.comments.unshift(
				new TaskComment({
					comment: this.comment,
					name: this.task.name,
					date: new Date()
				})
			)
			await Request.getInstance().post(`${process.env.VUE_APP_API_URL}tasks/${this.dealerId}/${this.task.id}/comment`, {
				createdBy: this.task.name,
				note: this.comment
			})

			this.comment = null
			this.addingComment = false
		}
	},
	async created() {
		try {
			const response = await Request.getInstance().get(`${process.env.VUE_APP_API_URL}tasks/${this.dealerId}/${this.taskId}`)
			this.task = new Task(response.data)
			const comments: Array<TaskComment> = []
			for (const c of this.task.comments) {
				comments.push(new TaskComment(c))
			}
			this.task.comments = comments
			this.loading = false
		} catch (error) {
			console.log(error)
			this.notFound = true
			this.loading = false
		}
	}
})
