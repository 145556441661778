












import Vue from "vue"
export default Vue.extend({
	props: {
		text: {
			default: "Item not found",
			type: String
		}
	}
})
