export default class TaskComment {
	comment?: string
	date = new Date()
	name?: string

	public constructor(data?) {
		if (data) {
			if (data.date) data.date = new Date(data.date)
			Object.assign(this, data)
		}
	}
}
