import TaskComment from "./TaskComment"

interface iTask {
	completedAt?: Date
	comments: Array<TaskComment>
	description?: string
	due?: Date
	email?: string
	externalId?: string
	id?: string
	name?: string
	title?: string
	updatedAt?: Date
}

export default class Task implements iTask {
	completedAt?: Date
	comments: Array<TaskComment> = []
	description?: string
	due?: Date
	email?: string
	externalId?: string
	id?: string
	name?: string
	title?: string
	updatedAt?: Date

	public constructor(data?) {
		if (data) {
			if (data.due) data.due = new Date(data.due)
			if (data.completedAt) data.completedAt = new Date(data.completedAt)
			if (data.updatedAt) data.updatedAt = new Date(data.updatedAt)
			Object.assign(this, data)
		}
	}
}
