import Vue from "vue"
import Vuex from "vuex"
import dealership from "./modules/dealership"

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		dealership
	}
})
