























import Task from "@/models/Task"
import Request from "@/services/Request"
import Vue from "vue"
import { DateTime } from "luxon"
export default Vue.extend({
	data() {
		return {
			loading: false as boolean,
			tasks: [] as Array<Task>,
			headers: [
				{ text: "Title", value: "title" },
				{ text: "Description", value: "description" },
				{ text: "Due", value: "due" },
				{ text: "", value: "overdue", sortable: false }
			]
		}
	},
	props: {
		dealerId: String,
		taskId: String
	},
	methods: {
		sort(items, indexArray, isDesc) {
			const index = indexArray[0]
			if (index === "due") {
				if (isDesc[0]) return items.sort((a, b) => b[index] - a[index])
				return items.sort((a, b) => a[index] - b[index])
			} else {
				if (isDesc[0]) return items.sort((a, b) => (a[index] < b[index] ? 1 : -1))
				return items.sort((a, b) => (a[index] >= b[index] ? 1 : -1))
			}
		},
		formatDate(date: Date) {
			if (!date) return ""
			return `${DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_MED)} (${DateTime.fromJSDate(date).toRelative()})`
		},
		isOverdue(date: Date) {
			if (DateTime.fromJSDate(date) < DateTime.local()) return true
			return false
		},
		rowSelected(item: Task) {
			this.$router.push({ name: "task", params: { dealerId: this.dealerId, taskId: item.externalId } })
		}
	},
	async created() {
		try {
			this.loading = true
			const response = await Request.getInstance().get(`${process.env.VUE_APP_API_URL}tasks/${this.dealerId}/${this.taskId}/related`)
			for (const task of response.data) {
				this.tasks.push(new Task(task))
			}
		} catch (error) {
			console.log(error)
		} finally {
			this.loading = false
		}
	}
})
