import axios from "axios"

interface IRequest {
	get(path: string): Promise<any>
}

export default class Request implements IRequest {
	private static instance: Request

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	private constructor() {}

	public static getInstance(): Request {
		return Request.instance ? Request.instance : new Request()
	}

	async get(path: string): Promise<any> {
		try {
			return await axios.get(path)
		} catch (error) {
			console.log(error)
			return Promise.reject()
		}
	}

	async post(path: string, data: any): Promise<any> {
		try {
			return await axios.post(path, data)
		} catch (error) {
			console.log(error)
			return Promise.reject()
		}
	}
}
